// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReactDOMRe = require("reason-react/src/legacy/ReactDOMRe.bs.js");
var Sudoku$Sudoku = require("./Sudoku.bs.js");

ReactDOMRe.renderToElementWithId(React.createElement(Sudoku$Sudoku.make, {}), "app");

var h = module.hot;

if (!(h == null)) {
  h.accept();
}

/*  Not a pure module */
