// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Cell$Sudoku = require("./Cell.bs.js");
var Grid$Sudoku = require("./Grid.bs.js");

function isTicking(state) {
  var match = state.status;
  return match === 1;
}

function init(param) {
  var cells = Grid$Sudoku.validate(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Grid$Sudoku.fillStatic(Belt_Array.map(Belt_Array.range(0, 80), Cell$Sudoku.empty), 0, 2), 1, 1), 2, 5), 3, 8), 5, 3), 8, 9), 9, 6), 11, 4), 13, 5), 15, 3), 17, 1), 20, 7), 21, 1), 22, 6), 25, 2), 28, 5), 31, 1), 33, 7), 34, 9), 38, 8), 39, 3), 40, 9), 42, 2), 45, 4), 48, 5), 50, 8), 55, 9), 57, 2), 58, 8), 59, 5), 63, 8), 65, 6), 66, 7), 67, 3), 68, 9), 69, 5), 70, 1), 71, 2), 73, 2), 76, 4), 78, 9));
  var validCount = Grid$Sudoku.validCount(cells);
  return {
          cells: cells,
          validCount: validCount,
          status: /* Playing */1,
          ticks: 0
        };
}

exports.isTicking = isTicking;
exports.init = init;
/* Cell-Sudoku Not a pure module */
