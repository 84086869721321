// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Cell$Sudoku = require("./Cell.bs.js");

function indexToPos(index) {
  return [
          index / 9 | 0,
          index % 9
        ];
}

function posToIndex(param) {
  return Math.imul(param[1], 9) + param[0] | 0;
}

function cellAtPos(grid, pos) {
  return Belt_Array.get(grid, posToIndex(pos));
}

function update(grid, f, index) {
  return Belt_Array.mapWithIndex(grid, (function (i, cell) {
                if (i === index) {
                  return Curry._1(f, cell);
                } else {
                  return cell;
                }
              }));
}

function fill(grid, index, value) {
  return update(grid, (function (cell) {
                if (value !== undefined) {
                  return {
                          id: cell.id,
                          valid: cell.valid,
                          fill: {
                            TAG: /* Filled */0,
                            _0: value
                          }
                        };
                } else {
                  return {
                          id: cell.id,
                          valid: cell.valid,
                          fill: /* Empty */0
                        };
                }
              }), index);
}

function fillStatic(grid, index, value) {
  return update(grid, (function (cell) {
                return {
                        id: cell.id,
                        valid: cell.valid,
                        fill: {
                          TAG: /* Static */1,
                          _0: value
                        }
                      };
              }), index);
}

function region(grid, param) {
  var x = Math.imul(param[0], 3);
  var y = Math.imul(param[1], 3);
  return Belt_Array.map(Belt_Array.keep(Belt_Array.map([
                      [
                        x,
                        y
                      ],
                      [
                        x + 1 | 0,
                        y
                      ],
                      [
                        x + 2 | 0,
                        y
                      ],
                      [
                        x,
                        y + 1 | 0
                      ],
                      [
                        x + 1 | 0,
                        y + 1 | 0
                      ],
                      [
                        x + 2 | 0,
                        y + 1 | 0
                      ],
                      [
                        x,
                        y + 2 | 0
                      ],
                      [
                        x + 1 | 0,
                        y + 2 | 0
                      ],
                      [
                        x + 2 | 0,
                        y + 2 | 0
                      ]
                    ], (function (param) {
                        return Belt_Array.get(grid, posToIndex(param));
                      })), Belt_Option.isSome), (function (prim) {
                return prim;
              }));
}

function row(grid, y) {
  return Belt_Array.slice(grid, Math.imul(9, y), 9);
}

function column(grid, x) {
  return Belt_Array.map(Belt_Array.keep(Belt_Array.map(Belt_Array.range(0, 8), (function (y) {
                        return Belt_Array.get(grid, posToIndex([
                                        x,
                                        y
                                      ]));
                      })), Belt_Option.isSome), (function (prim) {
                return prim;
              }));
}

function filled(grid) {
  return Belt_Array.keep(grid, (function (cell) {
                return Belt_Option.isSome(Cell$Sudoku.getValue(cell));
              }));
}

function duplicates(grid) {
  var grid$1 = filled(grid);
  return Belt_Array.map(Belt_Array.keep(grid$1, (function (cell) {
                    return Belt_Option.isSome(Belt_Array.getBy(grid$1, (function (other) {
                                      if (Caml_obj.caml_equal(Cell$Sudoku.getValue(cell), Cell$Sudoku.getValue(other))) {
                                        return cell.id !== other.id;
                                      } else {
                                        return false;
                                      }
                                    })));
                  })), (function (cell) {
                return cell.id;
              }));
}

function invalidIds(grid) {
  return Belt_Array.concatMany(Belt_Array.concatMany([
                  Belt_Array.map(Belt_Array.range(0, 8), (function (y) {
                          return duplicates(row(grid, y));
                        })),
                  Belt_Array.map(Belt_Array.range(0, 8), (function (x) {
                          return duplicates(column(grid, x));
                        })),
                  Belt_Array.map(Belt_Array.range(0, 2), (function (x) {
                          return Belt_Array.concatMany(Belt_Array.map(Belt_Array.range(0, 2), (function (y) {
                                            return duplicates(region(grid, [
                                                            x,
                                                            y
                                                          ]));
                                          })));
                        }))
                ]));
}

function validate(grid) {
  var invalids = invalidIds(grid);
  return Belt_Array.map(grid, (function (cell) {
                var match = Belt_Array.getBy(invalids, (function (id) {
                        return id === cell.id;
                      }));
                if (match !== undefined) {
                  return {
                          id: cell.id,
                          valid: false,
                          fill: cell.fill
                        };
                } else {
                  return {
                          id: cell.id,
                          valid: true,
                          fill: cell.fill
                        };
                }
              }));
}

function validCount(grid) {
  return Belt_Array.keep(grid, (function (cell) {
                if (cell.valid) {
                  return Belt_Option.isSome(Cell$Sudoku.getValue(cell));
                } else {
                  return false;
                }
              })).length;
}

exports.indexToPos = indexToPos;
exports.posToIndex = posToIndex;
exports.cellAtPos = cellAtPos;
exports.update = update;
exports.fill = fill;
exports.fillStatic = fillStatic;
exports.region = region;
exports.row = row;
exports.column = column;
exports.filled = filled;
exports.duplicates = duplicates;
exports.invalidIds = invalidIds;
exports.validate = validate;
exports.validCount = validCount;
/* Cell-Sudoku Not a pure module */
