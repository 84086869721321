// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Cell$Sudoku = require("./Cell.bs.js");
var Grid$Sudoku = require("./Grid.bs.js");
var State$Sudoku = require("./State.bs.js");
var Controls$Sudoku = require("./Controls.bs.js");

function Sudoku(Props) {
  var match = React.useReducer((function (state, msg) {
          if (typeof msg === "number") {
            if (msg !== 0) {
              if (State$Sudoku.isTicking(state)) {
                return {
                        cells: state.cells,
                        validCount: state.validCount,
                        status: state.status,
                        ticks: state.ticks + 1 | 0
                      };
              } else {
                return state;
              }
            } else {
              return State$Sudoku.init(undefined);
            }
          }
          var cells = Grid$Sudoku.validate(Grid$Sudoku.fill(state.cells, msg._0, msg._1));
          var validCount = Grid$Sudoku.validCount(cells);
          var status = validCount === 81 ? /* Won */2 : state.status;
          return {
                  cells: cells,
                  validCount: validCount,
                  status: status,
                  ticks: state.ticks
                };
        }), State$Sudoku.init(undefined));
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          var timerId = setInterval((function (param) {
                  return Curry._1(dispatch, /* Tick */1);
                }), 1000);
          return (function (param) {
                    clearInterval(timerId);
                    
                  });
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement(Controls$Sudoku.make, {
                  dispatch: dispatch,
                  status: state.status,
                  ticks: state.ticks
                }), React.createElement("div", {
                  className: "grid"
                }, Belt_Array.mapWithIndex(state.cells, (function (i, cell) {
                        return React.createElement(Cell$Sudoku.make, {
                                    cell: cell,
                                    change: (function (value) {
                                        return Curry._1(dispatch, /* Change */{
                                                    _0: i,
                                                    _1: value
                                                  });
                                      }),
                                    key: String(i)
                                  });
                      }))));
}

var make = Sudoku;

exports.make = make;
/* react Not a pure module */
