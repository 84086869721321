// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Printf = require("bs-platform/lib/js/printf.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");

function $percent$percent(a, b) {
  return [
          Caml_int32.div(a, b),
          Caml_int32.mod_(a, b)
        ];
}

function Controls(Props) {
  var dispatch = Props.dispatch;
  var status = Props.status;
  var ticks = Props.ticks;
  var match = $percent$percent(ticks, 3600);
  var match$1 = $percent$percent(match[1], 60);
  return React.createElement("div", {
              className: "controls"
            }, React.createElement("button", {
                  disabled: status === /* Generating */0,
                  onClick: (function (param) {
                      return Curry._1(dispatch, /* NewGame */0);
                    })
                }, "New Game"), React.createElement("div", {
                  className: "time"
                }, Curry._3(Printf.sprintf(/* Format */{
                          _0: {
                            TAG: /* Int */4,
                            _0: /* Int_d */0,
                            _1: {
                              TAG: /* Lit_padding */0,
                              _0: /* Zeros */2,
                              _1: 2
                            },
                            _2: /* No_precision */0,
                            _3: {
                              TAG: /* Char_literal */12,
                              _0: /* ":" */58,
                              _1: {
                                TAG: /* Int */4,
                                _0: /* Int_d */0,
                                _1: {
                                  TAG: /* Lit_padding */0,
                                  _0: /* Zeros */2,
                                  _1: 2
                                },
                                _2: /* No_precision */0,
                                _3: {
                                  TAG: /* Char_literal */12,
                                  _0: /* ":" */58,
                                  _1: {
                                    TAG: /* Int */4,
                                    _0: /* Int_d */0,
                                    _1: {
                                      TAG: /* Lit_padding */0,
                                      _0: /* Zeros */2,
                                      _1: 2
                                    },
                                    _2: /* No_precision */0,
                                    _3: /* End_of_format */0
                                  }
                                }
                              }
                            }
                          },
                          _1: "%02d:%02d:%02d"
                        }), match[0], match$1[0], match$1[1])));
}

var make = Controls;

exports.$percent$percent = $percent$percent;
exports.make = make;
/* react Not a pure module */
