// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function empty(id) {
  return {
          id: id,
          valid: true,
          fill: /* Empty */0
        };
}

function isStatic(cell) {
  var match = cell.fill;
  if (typeof match === "number" || match.TAG === /* Filled */0) {
    return false;
  } else {
    return true;
  }
}

function getValue(cell) {
  var match = cell.fill;
  if (typeof match === "number") {
    return ;
  } else {
    return match._0;
  }
}

function Cell(Props) {
  var cell = Props.cell;
  var change = Props.change;
  var handleChange = function (e) {
    var partial_arg = /[1-9]*([1-9])/;
    return Curry._1(change, Belt_Option.map(Belt_Option.flatMap(Belt_Option.flatMap(e.target.value, (function (param) {
                              return Caml_option.null_to_opt(param.match(partial_arg));
                            })), (function (matches) {
                          return Belt_Array.get(matches, matches.length - 1 | 0);
                        })), Caml_format.caml_int_of_string));
  };
  var value = cell.fill;
  var tmp;
  var exit = 0;
  if (typeof value === "number" || value.TAG === /* Filled */0) {
    exit = 1;
  } else {
    tmp = String(value._0);
  }
  if (exit === 1) {
    tmp = React.createElement("input", {
          pattern: "\\d*",
          type: "text",
          value: Belt_Option.mapWithDefault(getValue(cell), "", (function (prim) {
                  return String(prim);
                })),
          onChange: handleChange
        });
  }
  return React.createElement("div", {
              className: Cn.$plus(Cn.$plus("cell", Cn.on("static", isStatic(cell))), Cn.on("invalid", !cell.valid))
            }, React.createElement("div", {
                  className: "fill"
                }, React.createElement("div", {
                      className: "value"
                    }, tmp)));
}

var make = Cell;

exports.empty = empty;
exports.isStatic = isStatic;
exports.getValue = getValue;
exports.make = make;
/* react Not a pure module */
